html,
body,
#root {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
  color: rgb(65, 64, 64);
  font-family: sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spacestagram-title {
  display: block;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
}

.attribution {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  text-align: center;
  color: #666;
  padding: 0 1rem;
}

.apod-api {
  color: rgb(69, 51, 230);
  text-decoration: underline;
}

.apod-api:hover {
  color: rgb(138, 127, 240);
}

.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;
  position: relative;
}

.navitem {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: rgb(122, 121, 121);
  padding: 0.6rem;
  position: absolute;
}

.navitem::before {
  content: ' ';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
  background-color: rgb(104 102 102);
  opacity: 0;
  transform: scaleX(0.5);
  transition: all 0.2s ease-in-out;
}
.navitem.active::before {
  opacity: 1;
  transform: scaleX(1);
}

.home-button {
  right: 8rem;
}

.home-button:hover {
  color: rgb(65, 64, 64);
}

.liked-button {
  right: 5rem;
  padding: 0.7rem;
}

.liked-button:hover {
  color: rgb(65, 64, 64);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 1.5rem 0.8rem 4rem 0.8rem;
  flex-grow: 1;
  flex-shrink: 1;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0.6rem;
  max-width: 300px;
  text-align: center;
  font-family: arial;
  width: 100%;
  border-radius: 12px;
  transition: 0.1s transform ease-out;
}

.card:hover {
  transform: scale(1.02);
}

.modal-figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}

iframe {
  flex-shrink: 0;
  border-radius: 12px;
  margin: 1rem 0;
}

figcaption {
  display: flex;
  flex-direction: column;
  height: 40%;
}

.media {
  width: 100%;
  height: 220px;
  margin: auto;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 0;
}

.modal-content .media {
  margin: 0;
  height: 60%;
  flex-shrink: 0;
}

.source-link-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.source-link {
  right: 0;
  font-size: 0.7rem;
  padding: 0.2rem 0.8rem;
  color: rgb(109, 66, 158);
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

.title-explanation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.picture-of-the-day-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  height: 2em;
  margin: 0.7rem;
}

.explanation {
  font-size: 14px;
  padding: 0.3rem 1rem;
  margin: 0;
}

.modal-content .explanation {
  font-size: 0.9rem;
  padding: 1rem 1.5rem;
  overflow: auto;
}

.like-and-date-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0.5rem;
  margin-top: auto;
}

.like-button {
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 0;
}

.like-button:hover {
  background-color: rgba(249, 24, 128, 0.1);
  border-radius: 50%;
  color: rgb(237, 73, 86);
}

.like-button svg {
  margin-bottom: -2px;
}

.share-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0.4rem;
  line-height: 0;
}

.share-button:hover {
  background-color: rgba(214, 146, 241, 0.1);
  border-radius: 50%;
  color: rgb(175, 94, 207);
}

.share-button svg {
  margin-bottom: -2px;
}

.date-of-capture {
  font-size: 12px;
  color: #666;
  margin-top: 0.5rem;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(143, 140, 140, 0.5);
  z-index: 1;
}

.isOpen .modal-container {
  z-index: 999;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  max-height: 90vh;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.footer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding: 1.2rem 0;
}

.social-media {
  padding: 0.5rem;
  color: rgb(146 141 205);
}

.social-media:hover {
  color: rgb(184, 178, 247);
}

@media screen and (max-width: 800px) {
  .spacestagram-title {
    font-size: 1.2rem;
    padding-top: 1rem;
    text-align: start;
  }

  .attribution {
    margin-top: 1.2rem;
  }

  .liked-button {
    right: 3.5rem;
    margin-top: 1rem;
  }

  .navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1.5rem;
  }

  .card-wrapper {
    grid-template-columns: 1fr;
  }

  .liked-button {
    right: 3.5rem;
    margin-top: 1rem;
  }

  .home-button {
    right: 6rem;
    margin-top: 1rem;
  }

  .modal-content {
    width: 90vw;
    max-height: 80vh;
  }
}
