.LoadMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  border-radius: 18px;
  color: #fff;
  padding: 0.7rem 1.3rem;
  border: none;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;

  position: relative;
  z-index: 1;
  color: #ffffff;
  background-color: transparent;

  background-image: linear-gradient(
    45deg,
    hsla(229deg, 76%, 56%, 1),
    hsla(282deg, 71%, 65%, 1) 80%
  );
}

.LoadMoreButton:hover {
  color: #ffffff;
  background-image: linear-gradient(
    45deg,
    hsla(229deg, 76%, 56%, 1),
    hsla(282deg, 71%, 65%, 1) 80%
  );
}
.LoadMoreButton::before,
.LoadMoreButton::after {
  transition: 0.1s all ease-in-out;
}

.LoadMoreButton::before {
  background-image: linear-gradient(90deg, hsl(229deg 76% 56%), transparent);
}
.LoadMoreButton::after {
  background-image: linear-gradient(90deg, hsl(282deg 71% 65%), transparent);
}
.LoadMoreButton::before,
.LoadMoreButton::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5px;
  border-radius: 50px;
  z-index: -1;
  filter: blur(20px);
  transform: scale(0.7);
}
.LoadMoreButton::before {
  left: -20px;
}
.LoadMoreButton::after {
  right: -30px;
}
.LoadMoreButton:hover::before,
.LoadMoreButton:hover::after {
  transform: scale(0.8);
}
.LoadMoreButton:active::before,
.LoadMoreButton:active::after {
  transform: scale(0.5);
}

.LoadMoreButton:hover {
  background-color: rgb(146 141 205);
  color: #fff;
}
